body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dogs-list-top {
  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
}

@media only screen and (min-width: 820px) {
  .dogs-list-top {
    border-bottom-left-radius: 80% 180px;
    border-bottom-right-radius: 80% 180px;
  }
}